import { Eye, EyeClosed } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { memo, useCallback } from 'react';

import { useIsFlattenedView } from '~/hooks/search/useIsFlattenedView';

export const ToggleNestedItemsButton = memo(() => {
  const { setIsFlattenedView, isFlattenedView } = useIsFlattenedView();

  const onClick = useCallback(() => {
    setIsFlattenedView(!isFlattenedView);
  }, [isFlattenedView, setIsFlattenedView]);

  return (
    <Button
      appearance="ghost"
      color={isFlattenedView ? 'blue' : 'grey'}
      data-testid="TOGGLE_NESTED_ITEMS_BUTTON"
      prefix={isFlattenedView ? <EyeClosed className="size-4" /> : <Eye className="size-4" />}
      onClick={onClick}
      size="medium"
    >
      {isFlattenedView ? 'Collapse nested items' : 'Expand nested items'}
    </Button>
  );
});

ToggleNestedItemsButton.displayName = 'ToggleNestedItemsButton';
