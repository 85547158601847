import { tailwindMerge } from '@air/tailwind-variants';
import { forwardRef, memo, type ReactNode } from 'react';

import { InfiniteScrollSpinner } from '~/components/InfiniteScroll/InfiniteScrollSpinner';
import { KANBAN_COLUMN, KANBAN_COLUMN_SPINNER } from '~/constants/testIDs';

export interface KanbanColumnContainerProps {
  children?: ReactNode;
  header?: ReactNode;
  isLoading?: boolean;
  variant?: 'activeDropzoneWithChildren' | 'activeDropzone' | 'withDragOverlay';
}

/** BoxProps are excluded from type def to avoid passing additional props from parents using KanbanColumnProps */
export const KanbanColumnContainer = memo(
  forwardRef<HTMLDivElement, KanbanColumnContainerProps>(
    // we get the props just fine, TS, ref and all
    // eslint-disable-next-line react/prop-types
    ({ children, header, isLoading, variant }, ref) => {
      return (
        <div
          className={tailwindMerge(
            'flex h-full flex-col rounded-lg border border-grey-5 bg-grey-1 pb-2 transition-all',
            (variant === 'activeDropzone' || variant === 'activeDropzoneWithChildren') && 'border-blue-10 bg-blue-1',
            variant === 'withDragOverlay' && 'hidden',
          )}
          ref={ref}
          data-testid={KANBAN_COLUMN}
        >
          {header}
          {isLoading && <InfiniteScrollSpinner dataTestId={KANBAN_COLUMN_SPINNER} />}
          {children}
        </div>
      );
    },
  ),
);

KanbanColumnContainer.displayName = 'KanbanColumnContainer';
