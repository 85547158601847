import { type ReactNode } from 'react';

import { KanbanFakeGroup } from '~/components/KanbanFakeGroup';
import { KANBAN_NULL_STATE, KANBAN_NULL_STATE_ACTION, KANBAN_NULL_STATE_TEXT } from '~/constants/testIDs';

import { KanbanNullStateColumn } from './KanbanNullStateColumn';

export interface KanbanNullStateProps {
  action?: ReactNode;
  title?: string;
  text: ReactNode;
}

export const KanbanNullState = ({ action, text, title }: KanbanNullStateProps) => {
  return (
    <div className="relative h-full" data-testid={KANBAN_NULL_STATE}>
      <div className="absolute inset-x-0 top-[15%] flex flex-col items-center px-8 text-center">
        {!!title && <p className="mb-6 text-20 font-medium text-grey-11">{title}</p>}
        {!!text && (
          <p
            className="mx-auto mb-8 max-w-screen-sm text-center text-14 font-medium text-grey-10"
            data-testid={KANBAN_NULL_STATE_TEXT}
          >
            {text}
          </p>
        )}
        {!!action && (
          <div className="mt-4 flex gap-2" data-testid={KANBAN_NULL_STATE_ACTION}>
            {action}
          </div>
        )}
      </div>
      <KanbanFakeGroup>
        <KanbanNullStateColumn />
        <KanbanNullStateColumn />
        <KanbanNullStateColumn />
        <KanbanNullStateColumn />
        <KanbanNullStateColumn />
      </KanbanFakeGroup>
    </div>
  );
};
