import { BaseCustomField, CustomFieldValue } from '@air/api/types';
import { ComponentType, memo, useMemo } from 'react';

import { BOARD_STATS_BAR_HEIGHT } from '~/_components/BoardStatsBar';
import { KANBAN_VIEW_CONTAINER } from '~/constants/testIDs';

import { AirDndContext } from './AirDndContext';
import { KanbanColumnsManagers } from './KanbanColumnsManagers/KanbanColumnsManagers';
import { KanbanLoadingState } from './KanbanLoadingState';
import { useGetSingleSelectCustomFields } from './useGetSingleSelectCustomFields';

export interface KanbanViewProps {
  NullState: ComponentType;
  tableHeaderTopPos: number;
  customFields: BaseCustomField[] | undefined;
  baseCustomField: BaseCustomField | undefined;
  customFieldValues: CustomFieldValue[] | undefined;
  canViewCustomFields: boolean;
}

export const KanbanView = memo(
  ({
    NullState,
    tableHeaderTopPos,
    customFields,
    baseCustomField,
    customFieldValues,
    canViewCustomFields,
  }: KanbanViewProps) => {
    const { hasSingleSelectCustomFields } = useGetSingleSelectCustomFields(customFields);
    const loadingState = useMemo(() => <KanbanLoadingState />, []);

    const style = useMemo(
      () => ({
        height: `calc(100vh - ${tableHeaderTopPos + BOARD_STATS_BAR_HEIGHT}px)`,
      }),
      [tableHeaderTopPos],
    );

    if (!canViewCustomFields) return <NullState />;

    return (
      <AirDndContext>
        <div className="size-full bg-grey-2 py-4" data-testid={KANBAN_VIEW_CONTAINER} style={style}>
          {customFields ? (
            hasSingleSelectCustomFields ? (
              <KanbanColumnsManagers
                loadingState={loadingState}
                baseCustomField={baseCustomField}
                customFieldValues={customFieldValues}
              />
            ) : (
              <NullState />
            )
          ) : (
            loadingState
          )}
        </div>
      </AirDndContext>
    );
  },
);

KanbanView.displayName = 'KanbanView';
