import { NestedHidden } from '@air/next-icons';
import { memo } from 'react';

import { SearchToken } from '~/components/SearchBar/SearchToken';
import { SEARCH_BOARD_TOKEN } from '~/constants/testIDs';
import { useIsFlattenedView } from '~/hooks/search/useIsFlattenedView';

interface FlattenedBoardSearchTokenProps {
  boardName?: string;
}

export const FlattenedBoardSearchToken = memo(({ boardName }: FlattenedBoardSearchTokenProps) => {
  const { setIsFlattenedView } = useIsFlattenedView();

  return (
    <SearchToken
      testId={SEARCH_BOARD_TOKEN}
      tooltipText={`${boardName} (expanded)`}
      icon={<NestedHidden className="mr-1 size-3 shrink-0 text-blue-11" />}
      tokenName={boardName}
      color="blue"
      onCloseClick={() => {
        setIsFlattenedView(false);
      }}
    />
  );
});

FlattenedBoardSearchToken.displayName = 'FlattenedBoardSearchToken';
