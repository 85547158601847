import { Download, Fields, Link, Move } from '@air/next-icons';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useCallback } from 'react';

interface GetPublicBoardMenuOptionsParams {
  /** Callback for when the user selects to share the board */
  onShare?: () => void;
  /** Callback for when the user selects to download the board */
  onDownload?: () => void;
  /** Callback for when the user selects Copy to Air workspace */
  onCopyToAir?: () => void;
  customFieldsMenuOptions?: DropdownMenuOption[];
}

/**
 * Use this utility to build out the order of the options you'd see in an ellipsis menu for a board. Just pass the functions you want, with the board (and it's permissions).
 */
export const useGetPublicBoardMenuOptions = () => {
  const getBoardMenuOptions = useCallback(
    ({ onShare, onDownload, onCopyToAir, customFieldsMenuOptions }: GetPublicBoardMenuOptionsParams) => {
      const options: DropdownMenuOption[] = [];

      onShare &&
        options.push({
          id: 'share',
          label: 'Share a link',
          onSelect: onShare,
          prefix: <Link className="size-4" />,
          type: 'item',
        });

      onDownload &&
        options.push({
          id: 'download',
          label: 'Download',
          prefix: <Download className="size-4" />,
          onSelect: onDownload,
          type: 'item',
        });

      customFieldsMenuOptions &&
        customFieldsMenuOptions.length > 0 &&
        options.push({
          id: 'edit-custom-fields',
          prefix: <Fields className="size-4" />,
          label: 'Edit custom fields',
          options: customFieldsMenuOptions,
          type: 'sub',
        });

      onCopyToAir &&
        options.push({
          id: 'copy-to-air',
          prefix: <Move className="size-4" />,
          label: 'Copy to Air workspace',
          onSelect: onCopyToAir,
          type: 'item',
        });

      return options;
    },
    [],
  );

  return { getBoardMenuOptions };
};
