import { useTrackKanbanDragComplete } from '@air/analytics';
import { Items } from '@air/api';
import { BaseCustomField, BetweenItemsInput, CustomFieldValue, ViewTypeName, VisibleColumnType } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { Over } from '@dnd-kit/core';
import produce from 'immer';
import { isUndefined } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useKanbanContext } from '~/components/KanbanView/Providers/KanbanProvider';
import { currentViewIdSelector } from '~/store/configViews/selectors';
import {
  kanbanItemDragCancelAction,
  kanbanItemDragEndAction,
  kanbanItemPositionSuccessAction,
} from '~/store/kanbanManager/actions';
import { kanbanColumnItemCountSelector, kanbanColumnItemsSelector } from '~/store/kanbanManager/selectors';
import {
  DndItemType,
  DndSortableKanbanItemData,
  isDndDroppableKanbanColumnData,
  isDndSortableKanbanItemData,
} from '~/types/DndKit';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { getBoardIdFromPath, getShortIdFromPath } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

import { getChangedKanbanItem } from './getChangedKanbanItem';

type UpdateKanbanItemPositionParams = {
  activeData: DndSortableKanbanItemData;
  over: Over | null; // if not passed, just add to end of column
  baseCustomField: BaseCustomField;
  customFieldValues: CustomFieldValue[];
};

export const useUpdateKanbanItemPosition = () => {
  const { getState } = useAirStore();
  const { showToast } = useToasts();
  const dispatch = useDispatch();
  const { onUpdateItems, workspaceId } = useKanbanContext();
  const { trackKanbanDragComplete } = useTrackKanbanDragComplete();

  const updateKanbanItemPosition = useCallback(
    async ({ activeData, over, baseCustomField, customFieldValues }: UpdateKanbanItemPositionParams) => {
      const state = getState();

      const boardId = getBoardIdFromPath(window.location.pathname);
      const viewId = currentViewIdSelector(state)!; // we better have this one!;

      const overData = over?.data.current;
      let destinationColumnId: string | undefined;
      if (isDndSortableKanbanItemData(overData)) {
        destinationColumnId = overData.currentKanbanColumnId;
      } else if (isDndDroppableKanbanColumnData(overData)) {
        destinationColumnId = overData.kanbanColumnId;
      }

      if (!destinationColumnId || !customFieldValues) return;

      const originalColumnId = activeData.currentKanbanColumnId;
      const destinationColumnItems = kanbanColumnItemsSelector(state, destinationColumnId);
      const overItemIndex = destinationColumnItems.findIndex((i) => i.itemId === overData?.itemId);
      const changedItem = getChangedKanbanItem({
        activeData,
        baseCustomField,
        customFieldValues,
        nextKanbanColumnId: destinationColumnId,
      });

      const updatedColumnItems: Parameters<typeof kanbanItemDragEndAction>[0]['updatedColumnItems'] = {
        [destinationColumnId]: { data: [], total: 0 },
      };

      const destinationColumnTotal = kanbanColumnItemCountSelector(state, destinationColumnId);

      updatedColumnItems[destinationColumnId].data = produce(destinationColumnItems, (draft) => {
        // if destination is the same as original, remove the item where it was
        draft = draft.filter((i) => i.itemId !== activeData.itemId);
        draft.splice(
          overItemIndex < 0 ? 0 : overItemIndex, // if -1, it goes to the the top
          0,
          changedItem,
        );
        return draft;
      });

      if (!isUndefined(destinationColumnTotal)) {
        const destinationItemsDiff =
          updatedColumnItems[destinationColumnId].data.length - destinationColumnItems.length;
        updatedColumnItems[destinationColumnId].total = destinationColumnTotal + destinationItemsDiff;
      }

      if (originalColumnId !== destinationColumnId) {
        const originalColumnItems = kanbanColumnItemsSelector(state, originalColumnId);
        const originalColumnTotal = kanbanColumnItemCountSelector(state, originalColumnId);
        updatedColumnItems[originalColumnId] = { data: [], total: 0 };
        updatedColumnItems[originalColumnId].data = originalColumnItems.filter(
          (i) => i.itemId !== activeData.itemId && i.dndType !== DndItemType.kanbanUpload,
        );

        if (!isUndefined(originalColumnTotal)) {
          const itemsDiff = originalColumnItems.length - updatedColumnItems[originalColumnId].data.length;
          updatedColumnItems[originalColumnId].total = Math.max(originalColumnTotal - itemsDiff, 0);
        }
      }

      dispatch(kanbanItemDragEndAction({ updatedColumnItems }));
      const destinationColumnName =
        customFieldValues.find((cfValue) => cfValue.id === activeData.currentKanbanColumnId)?.value ||
        VisibleColumnType.unassignedCustomFieldValue;

      trackKanbanDragComplete({
        dragContent: [activeData.itemId],
        destinationColumnId: activeData.currentKanbanColumnId,
        destinationColumnName,
      });

      const betweenIds: BetweenItemsInput = {};
      try {
        if (originalColumnId !== destinationColumnId && !!baseCustomField) {
          const boardIds = activeData.dndType === DndItemType.kanbanBoard ? [activeData.itemId] : undefined;
          const assetIds = [DndItemType.kanbanAsset, DndItemType.kanbanFile].includes(activeData.dndType)
            ? [activeData.itemId]
            : undefined;
          const nextValue = customFieldValues.find((cfValue) => cfValue.id === destinationColumnId) || null;
          await onUpdateItems({
            boardIds,
            assetIds,
            baseCustomField,
            nextValue,
          });
        }
        // determine positioning to save in backend
        if (updatedColumnItems[destinationColumnId].data?.length ?? 0 > 1) {
          const indexOfItem = updatedColumnItems[destinationColumnId].data.findIndex(
            (i) => i.itemId === activeData.itemId,
          );
          let moveAfterId: string | undefined = undefined;
          let moveBeforeId: string | undefined = undefined;
          if (indexOfItem !== -1) {
            const moveAfterIndex = indexOfItem - 1;
            const moveBeforeIndex = indexOfItem + 1;
            if (moveAfterIndex !== -1) {
              moveAfterId = updatedColumnItems[destinationColumnId].data[moveAfterIndex].itemId || undefined;
            }
            moveBeforeId = updatedColumnItems[destinationColumnId].data[moveBeforeIndex]?.itemId;
          }
          if (moveAfterId || moveBeforeId) {
            betweenIds.moveAfterId = moveAfterId;
            betweenIds.moveBeforeId = moveBeforeId;
            const shortId = getShortIdFromPath(window.location.pathname);
            if (shortId || !boardId) {
              // TODO: update public positions
            } else if (workspaceId) {
              await Items.updateItemPositions({
                idsToMove: [activeData.itemId],
                betweenIds,
                boardId,
                view: {
                  id: viewId,
                  type: ViewTypeName.kanban,
                },
                workspaceId,
              });
            }
          }
        }
        dispatch(kanbanItemPositionSuccessAction());
      } catch (error) {
        showToast(`There was an error updating item position.`);

        reportErrorToBugsnag({
          error,
          context: `Failed to move kanban item`,
          metadata: {
            data: {
              activeData,
              overData,
              betweenIds,
            },
          },
        });
        // reset to what's in the store, just in case
        return dispatch(kanbanItemDragCancelAction());
      }
    },
    [getState, dispatch, trackKanbanDragComplete, onUpdateItems, workspaceId, showToast],
  );
  return { updateKanbanItemPosition };
};
